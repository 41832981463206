import React, { useState } from "react";
import GridViewIcon from "@mui/icons-material/GridView";
import { sxElementIcon } from "./style";
import PeopleOutlineIcon from "@mui/icons-material/PeopleOutline";
import SchoolOutlinedIcon from "@mui/icons-material/SchoolOutlined";
import SettingsOutlinedIcon from "@mui/icons-material/SettingsOutlined";
import Element from "../element";
import ContactSupportOutlinedIcon from "@mui/icons-material/ContactSupportOutlined";
import {
  APP_PREFIX_PATH,
} from "../../../../../configs/Appconfig";
import ArchitectureOutlinedIcon from "@mui/icons-material/ArchitectureOutlined";
import { ROLES } from "../../../../../constants/auth/roles";
import { educationProccessView, supportsListView, educationSystemView, humanResourcesView, mrpView, setPermissionsView, settingsView, supportCreate, supportView, attendanceView, attendanceRateView, studentsView, workersView, groupsView, programsView, unmarkedAttendanciesView, permissionLevelView, humanResourcesCreate, workersCreate, utilizationView } from "../../../../../permissions";

function MenuElements(props: { open: boolean }) {
  const { open } = props;
  const [element, setElement] = useState<string>("");
  return (
    <>
      <Element
        open={open}
        element={element}
        setElement={setElement}
        menuElement={"MRP"}
        menuIcon={<GridViewIcon sx={sxElementIcon} color="primary" />}
        menuCollapseElements={[]}
        permission={mrpView}
        disabled={false}
      />
            <Element
        permission={utilizationView}
        open={open}
        element={element}
        setElement={setElement}
        menuElement={"Anket"}
        menuIcon={<SchoolOutlinedIcon sx={sxElementIcon} color="primary" />}
        menuCollapseElements={[
          {
            name: "Anket Sessiyaları",
            url: `${APP_PREFIX_PATH}/student-surveys`,
            permissions: attendanceView,
          },
        ]}
      />
      <Element
        permission={utilizationView}
        open={open}
        element={element}
        setElement={setElement}
        menuElement={"Utilizasiya"}
        menuIcon={<SchoolOutlinedIcon sx={sxElementIcon} color="primary" />}
        menuCollapseElements={[
          {
            name: "Qruplar",
            url: `${APP_PREFIX_PATH}/utilization/groups`,
            permissions: attendanceView,
          },
          {
            name: "Müəllimlər",
            url: `${APP_PREFIX_PATH}/utilization/teachers`,
            permissions: attendanceRateView,
          },
          {
            name: "Mentorlar",
            url: `${APP_PREFIX_PATH}/utilization/mentors`,
            permissions: studentsView,
          },
          {
            name: "Tədris",
            url: `${APP_PREFIX_PATH}/utilization/education`,
            permissions: studentsView,
          },
          {
            name: "Exam Survey Planning",
            url: `${APP_PREFIX_PATH}/utilization/exam-survey-planning`,
            permissions: attendanceView,
          },
        ]}
      />
      <Element
        permission={educationProccessView}
        open={open}
        element={element}
        setElement={setElement}
        menuElement={"Tədris əməliyyatları"}
        menuIcon={<SchoolOutlinedIcon sx={sxElementIcon} color="primary" />}
        menuCollapseElements={[
          {
            name: "Davamiyyət",
            url: `${APP_PREFIX_PATH}/continuity`,
            permissions: attendanceView,
          },
          {
            name: "Davamiyyət göstəriciləri",
            url: `${APP_PREFIX_PATH}/continuity-result-student/`,
            permissions: attendanceRateView,
          },
          {
            name: "Tələbələr",
            url: `${APP_PREFIX_PATH}/students-lists`,
            permissions: studentsView,
          },
        ]}
      />
      <Element
        permission={educationSystemView}
        open={open}
        element={element}
        setElement={setElement}
        menuElement={"Tədris sistemi"}
        menuIcon={
          <ArchitectureOutlinedIcon sx={sxElementIcon} color="primary" />
        }
        menuCollapseElements={[
          {
            name: "İşçilər",
            url: `${APP_PREFIX_PATH}/worker-classes`,
            permissions: workersView,
          },
          {
            name: "Qruplar",
            url: `${APP_PREFIX_PATH}/groups`,
            permissions: groupsView,
          },
          {
            name: "Proqramlar",
            url: `${APP_PREFIX_PATH}/program`,
            permissions: programsView,
          },
          {
            name: "Yazılmamış davamiyyətlər",
            url: `${APP_PREFIX_PATH}/unmarked-attendancies`,
            permissions: unmarkedAttendanciesView,
          },
        ]}
      />
      <Element
        permission={humanResourcesView}
        open={open}
        element={element}
        setElement={setElement}
        menuElement={"İnsan resuları"}
        menuIcon={<PeopleOutlineIcon sx={sxElementIcon} color="primary" />}
        menuCollapseElements={[
          {
            name: "Təqvim",
            url: `${APP_PREFIX_PATH}/holidays`,
            permissions: humanResourcesView,
          },
          {
            name: "Akademik heyət",
            url: `${APP_PREFIX_PATH}/workers/add`,
            permissions: workersCreate,
          },
          {
            name: "Rol təyin et",
            url: `${APP_PREFIX_PATH}/roles/set`,
            permissions: humanResourcesView,
          },
        ]}
      />
      <Element
        permission={setPermissionsView}
        disabled={true}
        open={open}
        element={element}
        setElement={setElement}
        menuElement={"İcazələr"}
        menuIcon={ <img src="/icons/inbox.svg" />}
        menuCollapseElements={[
          {
            name: "Təqvim",
            url: `${APP_PREFIX_PATH}/holidays`,
            permissions: setPermissionsView,
          },
          {
            name: "Akademik heyət",
            url: `${APP_PREFIX_PATH}/workers/add`,
            permissions: setPermissionsView,
          },
        ]}
      />
      <Element
        permission={supportCreate}
        open={open}
        element={element}
        setElement={setElement}
        menuElement={"Dəstək"}
        menuIcon={
          <ContactSupportOutlinedIcon sx={sxElementIcon} color="primary" />
        }
        menuCollapseElements={[]}
      />
      <Element
        permission={supportsListView}
        open={open}
        element={element}
        setElement={setElement}
        url={'supports'}
        menuElement={"Dəstəklər"}
        menuIcon={
          <ContactSupportOutlinedIcon sx={sxElementIcon} color="primary" />
        }
        menuCollapseElements={[]}
      />
      <Element
        permission={settingsView}
        open={open}
        element={element}
        setElement={setElement}
        menuElement={"Tənzimləmələr"}
        disabled={false}
        menuIcon={<SettingsOutlinedIcon sx={sxElementIcon} color="primary" />}
        menuCollapseElements={[
          {
            name: "Set permissions",
            url: `${APP_PREFIX_PATH}/permissions`,
            permissions: setPermissionsView,
          },
          {
            name: "Permission levels",
            url: `${APP_PREFIX_PATH}/permissionlevels`,
            permissions: permissionLevelView,
          },
        ]}
      />
    </>
  );
}

export default MenuElements;
