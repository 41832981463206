import Select, { SelectChangeEvent } from "@mui/material/Select";
import React, { useState, useEffect } from "react";
import FormGroup from "@mui/material/FormGroup";
import Checkbox from "@mui/material/Checkbox";
import { styled } from "@mui/system";
import { useNavigate } from "react-router-dom";
import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  TextField,
  FormControlLabel,
  Tooltip,
  Switch,
} from "@mui/material";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import uuid from "react-uuid";
import { Module } from "../models/Module";
import {
  muiTooltipSx,
  plusIcon,
  indexNumber,
  deleteIcon,
  deleteOutlineIcon,
} from "./styles";
import { ThunkDispatch } from "@reduxjs/toolkit";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { fetchPrograms } from "../../../../../../store/reducers/programReducer";
import ConfirmModal from "../../../../../../components/custom-components/confirm-modal";
import Alert from "../../../../../../components/custom-components/alert";
import { AlertColor } from "@mui/material/Alert";
import FormLayout from "../../../../../../components/layout-components/form/form-layout";
import { ModuleService } from "../../../../../../network/services/moduleService/ModuleService";
import Header from "./components/header";
import Footer from "./components/footer";
import Progress from "../../../../../../components/custom-components/progress";
import { useParams } from "react-router-dom";
import { useGetProgramModulesList } from "../../../../../../api/programs";
import { RootState } from "../../../../../../store/reducers";
import { APP_PREFIX_PATH } from "../../../../../../configs/Appconfig";
const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "#6B696A",
  "&.Mui-checked": {
    color: "#468CBC",
  },
  "&.Mui-disabled.Mui-checked": {
    color: "#888",
  },
}));

function AddModule() {
  const [module, setModule] = useState<Module>({
    id: "",
    name: "",
    value: 0,
    subModules: [],
    isSurvey: false,
  });
  const [active, setActive] = useState<boolean>(false);
  const [complete, setComplete] = useState<boolean>(false);
  const param = useParams();
  
  const navigate = useNavigate();

  const [deletedElement, setDeletedElement] = useState<Module>({
    id: "",
    name: "",
    value: 0,
    subModules: [],
    isSurvey: false,
  });
  const [deleteConfirmationOpen, setDeleteConfirmationOpen] =
    useState<boolean>(false);
  const [alertInside, setAlertInside] = useState({
    text: "",
    severity: "success" as AlertColor,
  });
  const [alertOpen, setAlertOpen] = useState<boolean>(false);
  const [status, setStatus] = useState<boolean>(false);
  const dispatch = useDispatch<ThunkDispatch<any, any, any>>();
  const allPrograms = useSelector((state: RootState) => state.program.programs);
  let moduleService = new ModuleService();
  const { data, isLoading, refetch } = useGetProgramModulesList(+param.id!);
  useEffect(() => {
    getProgram();
    if (param!.id) {
      dispatch(fetchPrograms());
    }
  }, [param, data]);

  useEffect(() => {
    const modulesSum: number = module.subModules.reduce(
      (accumulator, currentValue) => accumulator + currentValue.value,
      0
    );
    const subModulesSum = module.subModules.map((item) => {
      if (item.subModules.length > 0) {
        const itemSubmoduleSum: number = item.subModules.reduce(
          (accumulator, currentValue) => accumulator + currentValue.value,
          0
        );
        if (item.value !== itemSubmoduleSum) {
          return false;
        }
      }
    });
    const isComplete: boolean = module.name
      ? module.value === modulesSum && !subModulesSum.includes(false)
      : false;
    setComplete(isComplete);
  }, [module]);

  const handleChange = (event: SelectChangeEvent): void => {
    const selectedValue: string = event.target.value;
    navigate(`${APP_PREFIX_PATH}/program/${selectedValue}`);
  };

  const getProgram = () => {
    if (data) {
      const selectedModule: any = {
        id: data.id,
        name: data.name,
        value: data.totalHours,
        subModules: data.modules.map((module) => ({
          id: module.id,
          name: module.name,
          value: module.hours,
          isSurvey: module.isSurvey,
          subModules: module.subModules.map((subModule) => ({
            id: subModule.id,
            name: subModule.name,
            value: subModule.hours,
            isSurvey: subModule.isSurvey,
          })),
        })),
      };
      setModule(selectedModule as Module);
    }
  };

  const handleChangeSwitch = (
    event: React.ChangeEvent<HTMLInputElement>
  ): void => {
    setActive((prev) => !prev);
  };

  const handleClick = (item: Module): void => {
    if (module.name) {
      if (item.id === module.id) {
        setModule({
          ...module,
          subModules: [
            ...module.subModules,
            {
              id: uuid(),
              name: "",
              value: 0,
              subModules: [],
              isSurvey: false,
            },
          ],
        });
      } else {
        if (!item.name) {
          setAlertOpen(true);
          setAlertInside({
            text: "İlk öncə modul adını yazın",
            severity: "warning" as AlertColor,
          });
        } else {
          const subModuleToAdd: Module = {
            id: uuid(),
            name: "",
            value: 0,
            isSurvey: false,
            subModules: [],
          };
          const subModulesCopy: Module[] = [...module.subModules];
          const indexToUpdate: number = subModulesCopy.findIndex(
            (subModule) => subModule.id === item.id
          );
          subModulesCopy[indexToUpdate].subModules.push(subModuleToAdd);
          setModule({ ...module, subModules: subModulesCopy });
        }
      }
    } else {
      setAlertOpen(true);
      setAlertInside({
        text: "İlk öncə proqram adını seçin",
        severity: "warning" as AlertColor,
      });
    }
  };
  const handleChangeCheckbox = (
    e: React.ChangeEvent<HTMLInputElement>,
    item: Module
  ) => {
    const updatedSubModules = module.subModules.map((subModule) => {
      if (subModule.id === item.id) {
        return { ...subModule, isSurvey: e.target.checked };
      } else {
        const updatedNestedSubModules = subModule.subModules.map((subModuleItem) => {
          if (subModuleItem.id === item.id) {
            return { ...subModuleItem, isSurvey: e.target.checked };
          }
          return subModuleItem;
        });
        return { ...subModule, subModules: updatedNestedSubModules };
      }
    });
  
    setModule({ ...module, subModules: updatedSubModules });
  };
  const handleChangeFields = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    item: Module
  ) => {
    const updatedSubModules = module.subModules.map((subModule) => {
      if (subModule.id === item.id) {
        return { ...subModule, name: e.target.value };
      } else {
        subModule.subModules.forEach((subModuleItem) => {
          if (subModuleItem.id === item.id) {
            subModuleItem.name = e.target.value;
          }
        });
        return subModule;
      }
    });
    setModule({ ...module, subModules: updatedSubModules });
  };

  const handleChangeFieldsValue = (
    e: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    item: Module
  ) => {
    const regex: RegExp = /[^\d]/g;
    const newValue: string = e.target.value.replace(regex, "");

    const updatedSubModules: Module[] = module.subModules.map((subModule) => {
      if (subModule.id === item.id) {
        return { ...subModule, value: Number(newValue) };
      } else {
        subModule.subModules.forEach((subModuleItem) => {
          if (subModuleItem.id === item.id) {
            subModuleItem.value = Number(newValue);
          }
        });
        return subModule;
      }
    });
    setModule({ ...module, subModules: updatedSubModules });
  };
  const handleDelete = (item: Module): void => {
    if (module.subModules.includes(item)) {
      setModule({
        ...module,
        subModules: module.subModules.filter((q) => q.id !== item.id),
      });
    } else {
      setModule({
        ...module,
        subModules: [
          ...module.subModules.map((q) => {
            return {
              ...q,
              subModules: q.subModules.filter((el) => {
                return el.id !== item.id;
              }),
            };
          }),
        ],
      });
    }
    setDeleteConfirmationOpen(false);
  };

  const handleDeleteConfirmationOpen = () => {
    setDeleteConfirmationOpen(true);
  };

  const handleDeleteConfirmationClose = () => {
    setDeleteConfirmationOpen(false);
  };

  const handleCloseAlert = (
    event?: React.SyntheticEvent | Event,
    reason?: string
  ) => {
    if (reason === "clickaway") {
      return;
    }
    setAlertOpen(false);
  };

  const handleSubmit = (event: React.SyntheticEvent) => {
    event.preventDefault();
    let postModel = {
      programId: module.id.toString(),
      modules: module.subModules.map((subModule, moduleIndex) => {
        return {
          name: subModule.name,
          version: `${moduleIndex + 1}`,
          hours: subModule.value,
          isSurvey: subModule.isSurvey,
          subModules: subModule.subModules.map((sub, subIndex) => {
            return {
              name: sub.name,
              hours: sub.value,
              version: `${moduleIndex + 1}.${subIndex + 1}`,
              isSurvey: sub.isSurvey,
            };
          }),
        };
      }),
    };

    setStatus(true);
    moduleService
      .add(postModel)
      .then((res) => {
        if (res.status) {
          setAlertOpen(true);
          setAlertInside({
            text: "Program yadda saxlanıldı",
            severity: "success" as AlertColor,
          });
          refetch();
        } else {
          setAlertOpen(true);
          setAlertInside({
            text: "Nə isə düz getmədi!",
            severity: "error" as AlertColor,
          });
        }
      })
      .catch((err) => {
        setAlertOpen(true);
        setAlertInside({
          text: "Nə isə düz getmədi!",
          severity: "error" as AlertColor,
        });
      })
      .finally(() => {
        setStatus(false);
      });
  };
  return (
    <>
      <FormLayout>
        <Header complete={complete} />

        {status || isLoading ? (
          <Progress />
        ) : (
          <>
             <Box
              component={"form"}
              id="myform"
              onSubmit={handleSubmit}
              maxWidth={"680px"}
            >
              <Box marginBottom={"24px"} alignItems={"center"} display={"flex"}>
                <Box marginRight={"16px"} sx={{ minWidth: 120 }}>
                  <FormControl sx={{ width: 308 }}>
                    <InputLabel id="demo-simple-select-label">
                      Program adı
                    </InputLabel>
                    <Select
                      labelId="demo-simple-select-label"
                      id="demo-simple-select"
                      value={module.id}
                      label="Program adı"
                      onChange={handleChange}
                    >
                      {React.Children.toArray(
                        allPrograms.length > 0 &&
                          allPrograms.map((option) => (
                            <MenuItem value={option.id}>{option.name}</MenuItem>
                          ))
                      )}
                    </Select>
                  </FormControl>
                </Box>

                <TextField
                  sx={{ width: 308, marginRight: "16px" }}
                  value={module.name && module.value}
                  disabled
                  id="outlined-basic"
                  label="Ümumi saat"
                  variant="outlined"
                />

                <Tooltip
                  placement="right"
                  arrow
                  title="Modul əlavə et"
                  PopperProps={{ sx: { muiTooltipSx } }}
                >
                  <Box onClick={() => handleClick(module)} sx={plusIcon}>
                    +
                  </Box>
                </Tooltip>
              </Box>

              {module.subModules.length > 0 &&
                React.Children.toArray(
                  module.subModules.map((item, index) => (
                    <>
                      <Box
                        marginBottom={"24px"}
                        alignItems={"center"}
                        display={"flex"}
                      >
                        <Box sx={indexNumber}>{index + 1}</Box>
                        <TextField
                          sx={{ width: 260, marginRight: "16px" }}
                          required
                          defaultValue={item.name}
                          key={`${item.id}`}
                          id="outlined-basic"
                          label={`Modul ${index + 1}`}
                          variant="outlined"
                          onBlur={(e: any) => handleChangeFields(e, item)}
                        />

                        <TextField
                          sx={{ width: 260, marginRight: "16px" }}
                          required
                          defaultValue={item.value}
                          key={`${item.value}`}
                          id="outlined-basic"
                          label="Saat"
                          variant="outlined"
                          onKeyUp={(e: any) => handleChangeFieldsValue(e, item)}
                          autoFocus
                          type="number"
                          inputProps={{ min: 1 }}
                        />
                        <Tooltip
                          placement="right"
                          arrow
                          title="Anket təyin et"
                          PopperProps={{ sx: { muiTooltipSx } }}
                        >
                          <FormGroup>
                            <FormControlLabel
                              control={<CustomCheckbox color="primary" />}
                              disabled={false}
                              checked={item.isSurvey}
                              onChange={(e: any) =>
                                handleChangeCheckbox(e, item)
                              }
                              label=""
                            />
                          </FormGroup>
                        </Tooltip>
                        <Tooltip
                          placement="right"
                          arrow
                          title="Modul əlavə et"
                          PopperProps={{ sx: { muiTooltipSx } }}
                        >
                          <Box
                            onClick={() => handleClick(item)}
                            sx={[plusIcon, { marginRight: "16px" }]}
                          >
                            +
                          </Box>
                        </Tooltip>

                        <Tooltip
                          placement="right"
                          arrow
                          title="Modulu sil"
                          PopperProps={{ sx: { muiTooltipSx } }}
                        >
                          <Box
                            sx={deleteIcon}
                            onClick={() => {
                              handleDeleteConfirmationOpen();
                              setDeletedElement(item);
                            }}
                          >
                            <DeleteOutlineIcon sx={deleteOutlineIcon} />
                          </Box>
                        </Tooltip>
                      </Box>
                      {item.subModules.length > 0 &&
                        React.Children.toArray(
                          item.subModules.map((subItem, subItemIndex) => (
                            <Box
                              justifyContent={"center"}
                              marginBottom={"24px"}
                              alignItems={"center"}
                              display={"flex"}
                            >
                              <Box sx={indexNumber}>
                                {index + 1}.{subItemIndex + 1}
                              </Box>
                              <TextField
                                sx={{ width: 236, marginRight: "16px" }}
                                required
                                defaultValue={subItem.name}
                                key={`${subItem.id}`}
                                id="outlined-basic"
                                label="Alt modul"
                                variant="outlined"
                                onBlur={(e: any) =>
                                  handleChangeFields(e, subItem)
                                }
                              />

                              <TextField
                                sx={{ width: 236, marginRight: "16px" }}
                                required
                                defaultValue={subItem.value}
                                key={`${subItem.value}`}
                                id="outlined-basic"
                                label="Saat"
                                variant="outlined"
                                onKeyUp={(e: any) =>
                                  handleChangeFieldsValue(e, subItem)
                                }
                                autoFocus
                                type="number"
                                inputProps={{ min: 1 }}
                              />
                              <Tooltip
                                placement="right"
                                arrow
                                title="Anket təyin et"
                                PopperProps={{ sx: { muiTooltipSx } }}
                              >
                                <FormGroup>
                                  <FormControlLabel
                                    control={<CustomCheckbox color="primary" />}
                                    disabled={false}
                                    checked={subItem.isSurvey}
                                    onChange={(e: any) =>
                                      handleChangeCheckbox(e, subItem)
                                    }
                                    label=""
                                  />
                                </FormGroup>
                              </Tooltip>

                              <Tooltip
                                placement="right"
                                arrow
                                title="Modulu sil"
                                PopperProps={{ sx: { muiTooltipSx } }}
                              >
                                <Box
                                  sx={deleteIcon}
                                  onClick={() => {
                                    handleDeleteConfirmationOpen();
                                    setDeletedElement(subItem);
                                  }}
                                >
                                  <DeleteOutlineIcon sx={deleteOutlineIcon} />
                                </Box>
                              </Tooltip>
                            </Box>
                          ))
                        )}
                    </>
                  ))
                )}
            </Box>

            <FormControlLabel
              sx={{
                marginLeft: "9px",
                marginTop: "25px",
                marginBottom: "44px",
              }}
              control={
                <Switch checked={active} onChange={handleChangeSwitch} />
              }
              label="Aktivdir"
            />
          </>
        )}
        <Footer />
      </FormLayout>

      {alertOpen && (
        <Alert
          open={alertOpen}
          text={alertInside.text}
          severity={alertInside.severity}
          handleClose={handleCloseAlert}
        />
      )}

      {deleteConfirmationOpen && (
        <ConfirmModal
          open={deleteConfirmationOpen}
          handleClose={handleDeleteConfirmationClose}
          handleAgree={() => handleDelete(deletedElement)}
          text={"Seçilmiş modulu silmək istədiyinizə əminsiniz?"}
          isWarning={false}
          sendBtnText="Sil"
        />
      )}
    </>
  );
}

export default AddModule;
