import { Box, Tab, Tabs, Button, Link } from "@mui/material";
import { sxTab } from "./style";
import { StatusQuery } from "../../models/StatusQuery";
import { ClassesCount } from "../../models/ClassesCount";
import FileDownloadOutlinedIcon from "@mui/icons-material/FileDownloadOutlined";
import { API_URL } from "../../../../../../configs/Appconfig";

interface TabPanelProps {
  classesStatusQuery: number;
  classesCountByStatus: ClassesCount[];
  handleChangeStatusQuery: (
    event: React.SyntheticEvent,
    newValue: number
  ) => void;
}
interface FormattedData {
  [key: string]: number;
}
export const TabHeader = (props: TabPanelProps) => {
  const { classesStatusQuery, handleChangeStatusQuery, classesCountByStatus } =
    props;
  const countData: FormattedData = {};
  classesCountByStatus.forEach((item) => {
    countData[item.status.toLowerCase()] = item.count;
  });

  return (
    <Box
      sx={{
        display: "flex",
        alignItems: "center",
        justifyContent: "space-between",
        padding: "16px 0px",
      }}
    >
      <Box>
        <Tabs
          value={classesStatusQuery}
          onChange={handleChangeStatusQuery}
          sx={{ minHeight: "48px" }}
        >
          <Tab sx={sxTab} label={`Aktiv qruplar(${countData.active})`} />
          <Tab sx={sxTab} label={`Açılacaq qruplar(${countData.new})`} />
          <Tab sx={sxTab} label={`Bitmiş qruplar(${countData.close})`} />
        </Tabs>
      </Box>
      <Box >
        <Link
          href={`${API_URL}api/classes/export/excel`}
          sx={{
            padding: "0",
            color: "#000",
            fontFamily: "Visby CF Bold, sans-serif",
            fontSize: "14px",
            flexDirection: "row",
            display: "flex",
            alignItems: "center",
            fontWeight: "700",
            letterSpacing: "0.4px",
            textDecorationLine: "underline",
            textTransform: "initial",
          }}
        >
          <FileDownloadOutlinedIcon sx={{ color: "#468CBC", mr: 1 }} />{" "}
          Excel kimi yüklə
        </Link>
      </Box>
    </Box>
  );
};
