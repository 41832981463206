export let Months = {
  Yanvar: "january",
  Fevral: "february",
  Mart: "march",
  Aprel: "april",
  May: "may",
  Iyun: "june",
  Iyul: "july",
  Avqust: "august",
  Sentyabr: "september",
  Oktyabr: "october",
  Noyabr: "november",
  Dekabr: "december",
};

export let Days = {
  1: "B.e",
  2: "Ç.a",
  3: "Ç",
  4: "C.a",
  5: "C",
  6: "Ş",
  0: "B.g",
};

export let Years = [2023, 2024, 2025, 2026, 2025];

export interface IClassModel {
  className: string | null;
  currentHour: number | null;
  endDate: string | null;
  id: number | null;
  programId: number | null;
  programName: string | null;
  sessionName: string | null;
  startDate: string | null;
  studyCount: number | null;
  totalHour: number | null;
  totalModules: number | null;
  workers: IWorkerModel | null;
}

export interface IClassCreateModel {
  name: string;
  programId: number;
  sessionId: number;
  roomId: number;
}

export interface IUpdateClassModel {
  name: string;
  programId: number;
  sessionId: number;
  roomId: number;
}

export interface IWorkerModel {
  email: string | null;
  id: number | null;
  name: string | null;
  role: string | null;
  roleId: number | null;
  surname: string | null;
}

export interface ISupportModel {
  id: number | null;
  class: string | null;
  createdDate: string | Date;
  description: string | null;
  images: any;
  note: string | null;
  status: string | null;
  supportCategory: string | null;
  title: string | null;
  user: any;
}
export interface IProgramModel {
  id: number;
  name: string;
  totalHours: number;
  modules: IModuleModel[];
}
export interface IModuleModel {
  id: number;
  name: string;
  hours: number;
  version: number;
  isSurvey: boolean;
  subModules: ISubModuleModel[];
}
export interface ISubModuleModel {
    id: number;
    name: string;
    hours: number;
    version: number;
    topModuleId: number;
    isSurvey: boolean;
}
export interface ISurveyModel{
  id: number;
  className: string;
  moduleId:number;
  moduleName:string;
  date:number
  isSurvey:boolean
}

export interface ISupportCreateModel {}

export interface ISupportUpdateModel {}

export interface ISupportCategoriesModel {}
