import {
    useDelete,
      useFetch, usePost, useUpdate,
  } from '../utils/api/reactQuery';
  import { apiRoutes } from '../apiRoutes';

export const useGetAllExamModules = () =>
  useFetch<any[]>(apiRoutes.workers.allExamModules);

export const useCreateExamSheet = (
  updater: (oldData: any, newData: any) => any) => 
  usePost<any, any>(apiRoutes.workers.createExamSheet, undefined, updater);

export const useCreateSurveySheet = (
  updater: (oldData: any, newData: any) => any) => 
  usePost<any, any>(apiRoutes.workers.createSurveySheet, undefined, updater)