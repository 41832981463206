import { Box } from "@mui/system";
import PageHeader from "../../../../components/custom-components/page-header";
import Breadcrumbs from "../../../../components/custom-components/breadcrumbs";
import BreadcrumbsLink from "../../../../components/custom-components/breadcrumbs/components/breadcrumbsLink";
import BreadcrumbsTypography from "../../../../components/custom-components/breadcrumbs/components/breadcrumbsTypography";
import PageTitle from "../../../../components/custom-components/page-title";
import { APP_PREFIX_PATH } from "../../../../configs/Appconfig";
export default function Header() {
  return (
    <PageHeader>
      {" "}
      <Box>
        {" "}
        <Breadcrumbs>
          {" "}
          <BreadcrumbsLink name="Home" url={APP_PREFIX_PATH} />{" "}
          <BreadcrumbsTypography name="Anket sessiyaları" />{" "}
        </Breadcrumbs>{" "}
        <PageTitle name="Anket sessiyaları" />{" "}
      </Box>{" "}
    </PageHeader>
  );
}
