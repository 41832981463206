import { GridColDef } from "@mui/x-data-grid";
import {
  Box,
  Typography,
  Tab,
  Tabs,
  FormGroup,
  FormControlLabel,
  Checkbox,
} from "@mui/material";
import moment from "moment";
import TabContext from "@mui/lab/TabContext";
import TabPanel from "@mui/lab/TabPanel";
import DataGrid from "../../../../components/custom-components/data-grid";
import { useEffect, useState } from "react";
import { localeText } from "../../../../configs/Locales";
import { BaseService } from "../../../../network/services/base/BaseService";
import Header from "../components";
import { ISurveyModel } from "../../../../types";
import styled from "@emotion/styled";
const sxTab = {
  display: "flex",
  padding: "0px 8px",
  alignItems: "center",
  fontSize: "14px",
  fontWeight: "500",
  color: "#000000",
  textTransform: "none",
  "&.Mui-selected": {
    fontFamily: "Visby CF Bold, sans-serif",
    color: "#000000",
  },
};
const CustomCheckbox = styled(Checkbox)(({ theme }) => ({
  color: "#6B696A",
  "&.Mui-checked": {
    color: "#468CBC",
  },
  "&.Mui-disabled.Mui-checked": {
    color: "#888",
  },
}));

export const ServeySessions = () => {
  const [pageSize, setPageSize] = useState<number>(10);
  const [classesServey, setClassesServey] = useState<ISurveyModel[]>([]);
  const [isServey, setIsServey] = useState("false");
  const classesServeyService = new BaseService(
    `/api/Surveys/dates?isSurvey=${isServey}`
  );
  const postServeysDone = new BaseService(
    "/api/Surveys/done"
  );
  const handleChange = (event: React.SyntheticEvent, newValue: string) => {
    setIsServey(newValue);
  };
  const getClassesServey = async () => {
    await classesServeyService
      .getAll()
      .then((res) => {
        // Bugünün tarihini al
        const today = new Date().getTime();
        // Veriyi bugüne en uzak olanı en başa alacak şekilde sırala
        const sortedData = res.data.sort((a: ISurveyModel, b: ISurveyModel) => {
          const dateA = new Date(a.date).getTime();
          const dateB = new Date(b.date).getTime();
  
          // Tarihleri bugünden itibaren en uzak olana göre sırala
          return Math.abs(dateB - today) - Math.abs(dateA - today);
        });
  
        setClassesServey(sortedData);
      })
      .catch((err) => (console.log(err), setClassesServey([])));
  };
  useEffect(() => {
    getClassesServey();
  }, [isServey]);
  const handdleChangeisServey = async (
    classId: number,
    moduleId: number,
    isServey: boolean
  ) => {
    setClassesServey((prev) =>
      prev.map((item) =>
        item.id === classId && item.moduleId === moduleId
          ? { ...item, isSurvey: !isServey }
          : item
      )
    );
    setTimeout(() => {
      postServeysDone.add({ classId, moduleId }).then((res) => {
        getClassesServey();
      });
    }, 500);
  };
  const columns: GridColDef[] = [
    {
      field: "className",
      headerName: "Qrup",
      flex: 0.7,
    },
    {
      field: "moduleName",
      headerName: "Modul",
      flex: 1,
    },
    {
      field: "date",
      headerName: "Anket tarixi",
      flex: 1,
      renderCell: (params) => {
        return (
          <Typography sx={{ fontSize: "14px" }}>
            {moment(params.value).format(" D MMMM YYYY")}
          </Typography>
        );
      },
    },
    {
      field: "isSurvey",
      headerName: "",
      flex: 1,
      renderCell: (params) => {
        return (
          <FormGroup>
            <FormControlLabel
              control={<CustomCheckbox color="primary" />}
              disabled={false}
              checked={params.row.isSurvey}
              onChange={() =>
                handdleChangeisServey(
                  params.row.id,
                  params.row.moduleId,
                  params.row.isSurvey
                )
              }
              label=""
            />
          </FormGroup>
        );
      },
    },
  ];
  return (
    <>
      <Header />
      <TabContext value={isServey}>
        <Box
          sx={{
            display: "flex",
            alignItems: "center",
            justifyContent: "space-between",
          }}
        >
          <Box sx={{ borderBottom: 1, borderColor: "divider" }}>
            <Tabs
              value={isServey}
              onChange={handleChange}
              sx={{ minHeight: "48px" }}
            >
              <Tab
                disableRipple
                sx={sxTab}
                label="Keçiriləcək anketlər"
                value={"false"}
              />
              <Tab
                disableRipple
                sx={sxTab}
                label="Tamamlanmış anketlər"
                value={"true"}
              />
            </Tabs>
          </Box>
        </Box>
        <TabPanel sx={{ padding: "30px 0px" }} value={"false"}>
          <Box sx={{ width: "100%" }}>
            <DataGrid
              data={classesServey}
              columnsData={columns}
              pageSizeData={pageSize}
              setPageSize={setPageSize}
              disableColumnMenu={false}
              localeText={localeText}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
                pagination: {
                  labelRowsPerPage: "Səhifədəki sətir sayı:",
                },
              }}
            />
          </Box>
        </TabPanel>
        <TabPanel sx={{ padding: "30px 0px" }} value={"true"}>
          <Box sx={{ width: "100%" }}>
            <DataGrid
              data={classesServey}
              columnsData={columns}
              pageSizeData={pageSize}
              setPageSize={setPageSize}
              disableColumnMenu={false}
              localeText={localeText}
              componentsProps={{
                toolbar: {
                  showQuickFilter: true,
                  quickFilterProps: { debounceMs: 500 },
                },
                pagination: {
                  labelRowsPerPage: "Səhifədəki sətir sayı:",
                },
              }}
            />
          </Box>
        </TabPanel>
      </TabContext>
    </>
  );
};
